import { useState, useEffect, useRef } from "react";
import { Image } from "@arco-design/web-react";
import { IconPlus } from "@arco-design/web-react/icon";
import cs from "classnames";
import { close_icon } from "@/assets/creationscriptimage";
import allowTypeStore from "@/stores/allowTypeStore";
import "./index.less";
import { toJS } from "mobx";
import { audio_icon, video_icon } from "@/assets/taskcenter";
import VideoHoc from "../VideoHoc/VideoHoc";
import creationStores from "@/stores/creationScriptStore";
import { getSimpleMedia } from "@/services/createSpace/graphQ/getSimpleMedia";
import { observer } from "mobx-react";
import _ from "lodash";
import CommonUpload from "@/components/business/CommonUpload";
const UploadVideo = ({ index, value, send }) => {
  const [fileList, setFileList] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const showRef = useRef(false);
  const flexBoxRef = useRef(null);
  const [eleHideCount, setElemHideCount] = useState(0);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);

  const getVideoInfo = async () => {
    const nArr = value.map((v) => v.uid);
    try {
      // 使用 map 生成所有的 Promise
      const promises = nArr.map((v) => getSimpleMedia(v));

      if (!promises) {
        setFileList([]);
        return;
      }
      // 使用 Promise.all 等待所有 Promise 完成
      const results = await Promise.all(promises);

      // 更新状态

      setFileList(
        results
          .map((v) => ({
            ...v,
            uid: v?.id,
            play_info: v?.play_info,
          }))
          .filter((v) => v.uid),
      );
    } catch (error) {
      console.error("获取视频信息失败:", error);
      // 你可以在这里添加错误处理逻辑，例如显示错误提示
    }
  };
  useEffect(() => {
    if (value && value.length) {
      getVideoInfo();
    }
  }, [value]);
  useEffect(() => {
    setFileList(
      value && value.length
        ? _.uniqBy(
            [
              ...fileList,
              ...value.map((v) => ({
                ...v,
                uid: v.id,
                play_info: v.play_info,
              })),
            ],
            "uid",
          )
        : [],
    );
  }, [value]);
  const elementsHideRef = useRef([]);

  const handleResize = () => {
    if (showRef.current) {
      return;
    }
    if (flexBoxRef.current) {
      elementsHideRef.current.forEach((v) => {
        v.style.display = "block";
      });
      const maxWidth = flexBoxRef.current.clientWidth;
      const flexBox = flexBoxRef.current;
      const contentBoxes: any = Array.from(
        flexBox.querySelectorAll(".content-box"),
      );
      let currentLineWidth = 0;

      let lineCount = 1; // 初始化行数为1
      const elementsToHide = []; // 用于存储需要隐藏的元素索引

      contentBoxes.forEach((box, index) => {
        if (lineCount === 1) {
          // 如果是第一行
          if (currentLineWidth + box.offsetWidth + 12 <= maxWidth) {
            // 如果当前行能放下这个元素，则更新当前行宽度
            currentLineWidth += box.offsetWidth + 12;
          } else {
            // 否则，换行到第二行，并更新行数
            lineCount++;
            currentLineWidth = box.offsetWidth + 12; // 重置当前行宽度为当前元素的宽度
          }
        } else if (lineCount === 2) {
          // 如果是第二行
          if (currentLineWidth + box.offsetWidth + 12 <= maxWidth) {
            // 如果第二行能放下这个元素，则更新当前行宽度
            currentLineWidth += box.offsetWidth + 12;
          } else {
            // 否则，隐藏当前元素及之后的元素
            elementsToHide.push(index);
          }
        }
      });

      // 隐藏需要隐藏的元素

      elementsToHide.forEach((index) => {
        contentBoxes[index].style.display = "none";
        elementsHideRef.current.push(contentBoxes[index]);
      });
      setElemHideCount(elementsToHide.length);
    }
  };
  const getfileTypeDom = (item) => {
    const actionMap = {
      MUSIC: {
        url: audio_icon,
      },
      MOVIE: {
        url: video_icon,
      },
      PICTURE: item,

      1: {
        url: audio_icon,
      },
      2: {
        url: video_icon,
      },
      3: item,
    };
    return actionMap[item.type];
  };
  const clearHidden = () => {
    elementsHideRef.current.forEach((v) => {
      v.style.display = "block";
    });
    setIsShow(true);
  };
  const deleteFileList = (id) => {
    fileList.splice(
      fileList.findIndex((v) => v.uid == id),
      1,
    );
    setFileList([...fileList]);
    creationStores.changeCaption(index, "materials", fileList);
    send();
  };
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        handleResize();
      });
    });

    if (flexBoxRef.current) {
      resizeObserver.observe(flexBoxRef.current);
    }

    // 组件卸载前停止观察
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  useEffect(() => {
    showRef.current = isShow;
  }, [isShow]);
  useEffect(() => {
    handleResize();
  }, [fileList]);
  // 1 视频2音频3图片4文件夹
  return (
    <div className="video-upload-root" ref={flexBoxRef}>
      <CommonUpload
        allowMetarial={[1, 4]}
        defalutValue="material"
        importCollection={
          creationStores.dataSource.map((v) => v.materials).flat() &&
          creationStores.dataSource.map((v) => v.materials).flat().length
            ? creationStores.dataSource
                .map((v) => v.materials)
                .flat()
                .map((v) => {
                  if (v?.id) {
                    return v.id;
                  } else {
                    return "";
                  }
                })
            : []
        }
        visible={uploadModalVisible}
        onCancel={() => {
          setUploadModalVisible(false);
        }}
        maxCollectionLength={10000}
        defaultCollection={[]}
        // allowType={toJS(allowTypeStore.getAllVideoTypeString())}
        allowType={toJS(allowTypeStore.getAllVideoTypeString())}
        handleOkBtnClick={(info) => {
          const nArr =
            info && info.length
              ? _.uniqBy(
                  [
                    ...fileList,
                    ...info.map((v) => ({
                      ...v,
                      uid: v.id,
                      play_info: v.play_info,
                    })),
                  ],
                  "uid",
                )
              : [];
          setFileList(nArr);
          creationStores.changeCaption(index, "materials", nArr);
          send();
          setUploadModalVisible(false);
        }}
      />

      <div className="arco-upload-trigger-picture content-box">
        <input
          onClick={() => {
            setUploadModalVisible(true);
          }}
          className="video-upload"
        />
        <div className="arco-upload-trigger-picture-text">
          <IconPlus style={{ fontSize: 20 }} />
          <div style={{ fontSize: 14 }}>上传素材</div>
        </div>
      </div>
      {fileList.map((item, index) => (
        <div
          key={index}
          className={cs(
            "render-List content-box",
            index == fileList.length - eleHideCount - 1 &&
              eleHideCount &&
              !isShow
              ? "last-box"
              : "",
          )}>
          <Image
            src={close_icon}
            preview={false}
            className="render-close-icon"
            width={
              index == fileList.length - eleHideCount - 1 &&
              eleHideCount &&
              !isShow
                ? 0
                : 12
            }
            onClick={() => {
              deleteFileList(item.uid);
            }}
          />

          {/* <Image
            style={{
              display:
                item.type == 1 || item.type == "MOVIE" ? "block" : "none",
            }}
            src={play_icon}
            preview={false}
            className="render-play"
            width={16}
          /> */}
          <VideoHoc
            url={item.play_info?.play_url}
            disMuted={true}
            style={{ height: "80px" }}>
            <Image
              src={item.play_info?.cover_url}
              preview={false}
              className="render-img"
              width={"100%"}
              style={{ width: "80px", height: "80px" }}
            />
          </VideoHoc>
          {/* <VideoHoc url={""} style={undefined} /> */}
          <div
            onClick={clearHidden}
            className="last-hidden"
            style={{
              display:
                index == fileList.length - eleHideCount - 1 &&
                eleHideCount &&
                !isShow
                  ? "flex"
                  : "none",
            }}>
            +{eleHideCount}
          </div>
        </div>
      ))}
      {/* <div>{wrapCount}</div> */}
    </div>
  );
};

export default observer(UploadVideo);
